import React,{ useContext, useEffect, useState } from 'react';
import userContext from "../context/userContext";
import { BASE_API_URL } from '../constant';
import { Link } from "react-router-dom";
import {ExpandLess ,ExpandMore  } from "@material-ui/icons";
import {
  ListItem,
  ListItemText,
  List,
  Collapse,
} from "@material-ui/core";

 function Sidebar() {
    const [sideBar,setSidebar] = React.useState([]);
    const { userData, setUserData } = useContext(userContext);
    const [open, setOpen] = React.useState(false);
    const [userRequest, setUserRequest] = useState([
      { id: "6", open: false },
      { id: "7", open: false },
      { id: "9", open: false },
      { id: "10", open: false },
      { id: "12", open: false },
      { id: "14", open: false }
    ]);

    const handleClick = (id) => {
      //setOpen(!open);
      const newTodos = userRequest.map((todo) => {
        if (todo.id === id) {
          return {...todo, open: !todo.open};
        }
        return todo;
      });
    
      setUserRequest(newTodos);
    };
  //get sidebar data
  const sidebarData = async () => {
    const options = {
      method: "POST",
      headers: {
        Authorization: userData.token,
      },
      body : JSON.stringify({
      })
    };
    const response = await fetch(`${BASE_API_URL}sidebar-menu`,options);
    const data = response.json(); 
    
    data.then((res) => {  
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setSidebar(res.data)
    })
    .catch((error) => console.log(error));
  };

  useEffect(() => {
    sidebarData()
  }, []);
  //
  return (
    <>
     { sideBar.length && sideBar.length ? sideBar.map((mu,i) => {
        return <>
            {/*<ListItem component={Link} to={mu?.routeName === '#' ?  '' : `/${mu.routeName}`}>*/}
            <ListItem component="a" href={mu?.routeName === '#' ?  '#' : `/${mu.routeName}`}>
        <ListItemText primary={mu.menu} style={{ color: "white" }} />
        {mu.sub.length? open ? <ExpandLess onClick={()=>{handleClick(mu.menu_id)}} />:<ExpandMore onClick={()=>{handleClick(mu.menu_id)}} />:""}
        </ListItem>
         {mu.sub.length?<Collapse in={userRequest.find(item => item.id === mu.menu_id).open} timeout="auto" unmountOnExit>
          {mu.sub.map((smu,index)=>{
            return <List component="div" disablePadding>
            {/*<ListItem button component={Link} to={smu.routeName}>*/}
            <ListItem button component="a" href={`/${smu.routeName}`}>
              <ListItemText primary={smu.sub_menu_name} />
            </ListItem>
          </List>
          })}
        </Collapse>:""} </>
      }):""
    }
    
    </>
  );
}
export default Sidebar;


