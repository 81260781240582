import React, { useState, useEffect, useContext,useRef  } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import Button from '@material-ui/core/Button';
import {BASE_API_URL,BASE_URL} from '../../constant';
import userContext from "../../context/userContext";


const CreateLink = () => {
    const history = useHistory();
    const inputRef = useRef(); 
    const { userData, setUserData } = useContext(userContext);
    const [selectedUser, setSelectedUser] = useState('{AffiliateUser}');
    const [selectedService, setSelectedService] = useState('{Service}');
    const [serviceType, setServiceType] = useState([]);
    const [affiliateUser, setAffiliateUser] = useState([]);
    const [affLinkData,setAffLinkData] = useState({
        affiliateUsersId:"",
        affiliateUrl:"",
        serviceId:"",
        isBlocked:1
    })

    let name, value;
    const handleInputs = (e) => {
        name=e.target.name;
        value=e.target.value;
        setAffLinkData({...affLinkData, [name]:value}); 
    }

    const getServiceType = async () => {
        const options = {
          method: "GET",
          headers: {
            Authorization: userData.token,
          },
        };
        const response = await fetch(`${BASE_API_URL}service-types?limit=100`, options);
        const data = response.json();
    
        data.then((data) => { 
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
          setServiceType(data.data.result);
        })
        .catch((error) => console.log(error));
    };

    const getAffiliateUser = async () => {
        const options = {
          method: "GET",
          headers: {
            Authorization: userData.token,
          },
        };
        const response = await fetch(`${BASE_API_URL}affiliate-user-list?limit=100`, options);
        const data = response.json();
    
        data.then((data) => { 
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
          setAffiliateUser(data.data);
        })
        .catch((error) => console.log(error));
    };
    useEffect(() => {
        getServiceType();
        getAffiliateUser();
        // eslint-disable-next-line
      }, []);

    const setServiceName = (v)=>{
    const sn = serviceType.filter((st)=> st._id==v)
    setSelectedService(sn[0].name.toLowerCase().replace(/\s+/g, '-'));
    }
    const setaffUserName = (v)=>{
    const sn = affiliateUser.filter((au)=> au._id==v)
    setSelectedUser(sn[0].name.toLowerCase().replace(/\s+/g, '-'));
    }

    const PostData = async (e) => {
        e.preventDefault();
        affLinkData.affiliateUrl=inputRef.current.value;
        affLinkData.isBlocked=affLinkData.isBlocked==1?false:true;
        const {affiliateUsersId,affiliateUrl,serviceId,isBlocked } = affLinkData;
        if(affiliateUsersId==''){
            alert('Please select Affiliate User');
            return false;
        }
        if(serviceId==""){
            alert('Please select service');
            return false;
        }
        const res = await fetch(`${BASE_API_URL}createAffiliateLink`,{
            method:"POST",
            headers:{
                    "Content-Type":"application/json",
                    "authorization":userData.token
            },
            body:JSON.stringify({
                affiliateUsersId,affiliateUrl,serviceId,isBlocked
            })          
        });

        const data=await res.json();
        if (res.status === 401) {
              localStorage.removeItem("auth-token");
              setUserData({
                token: "",
              });
        }
        
        if(!data)
        {
            window.alert("Somthing went wrong");
        }
        else
        {
            if(data.statusCode === 200){
            window.alert('Link created successfully');
            history.push("/affiliate-links");
            }
            else{
                window.alert(data.message);
            }
        }
    }
return ( 
    <div class="container">
        <div class="row">
            <h2 class="page-title my-3 ">Create Affiliate Link</h2>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="border-bottom pb-2 ">
                    <div id="services" className="tab-pane active my-4">
                        <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3 ">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Select Affiliate User<span className='orangeTxt'>*</span></label>
                                        <select name="affiliateUsersId" value={affLinkData.affiliateUsersId} required className="form-control" onChange={(e) => { handleInputs(e); setaffUserName(e.target.value); }} >
                                            <option value="">Select User</option>
                                            {affiliateUser.map(element => {
                                                return <option value={element._id}>{element.name} </option>
                                            })}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Select Service<span className='orangeTxt'>*</span></label>
                                        <select name="serviceId" value={affLinkData.serviceId} required className="form-control" onChange={(e) => { handleInputs(e); setServiceName(e.target.value); }} >
                                            <option value="">Select Service</option>
                                            {serviceType.map(element => {
                                                return <option value={element._id}>{element.name} </option>
                                            })}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Affiliate Link<span className='orangeTxt'>*</span></label>
                                        <input type="text" ref={inputRef}name="affiliateUrl" className="form-control" value={`${BASE_URL}service/${selectedService}/${selectedUser}`} readOnly onChange={handleInputs} />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Status<span className='orangeTxt'>*</span></label>
                                        <select name="isBlocked" className="form-control" value={affLinkData.isBlocked} onChange={handleInputs} >
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                            </div>          
                        </div>
                        <Button  color="primary" disableElevation variant="contained" onClick={PostData} >Save</Button>
                        </div>          
                    </div>
            </div>                    
        </div>                
    </div>
            
    );
}

export default CreateLink;