import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import { Link } from 'react-router-dom'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import { BASE_API_URL } from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import * as XLSX from 'xlsx';

import './../../styles/cssformui.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { format } from 'date-fns'; // Optional: For formatting the date

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell style={{ fontWeight: 'bold' }}>ID</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Date</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Affiliate User Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Service Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Affiliate Link</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Count</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ users, handleChange, selectedUser, serviceType, handleService, selectedService }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Affiliate Link
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="affiliate-user-label">By User</InputLabel>
          <Select
            labelId="affiliate-user-label"
            id="affiliate-user-select"
            value={selectedUser}
            onChange={handleChange}
            label="By User"
            >
            <MenuItem value="">Select User</MenuItem>
            {users.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Service Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedService}
            onChange={handleService}
          >
            <MenuItem value="">Select Service Type</MenuItem>
            {serviceType.map(element => {
              return <MenuItem value={element._id}>{element.name} </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function AffiliateLink({ classes }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const { userData, setUserData } = useContext(userContext);
  const [count, setCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [affiliateLinks, setAffiliateLinks] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [selectedService, setSelectedService] = React.useState("");

  //Function to gt all users
  const fetchUsers = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: userData.token,
        },
      };
      const response = await fetch(`${BASE_API_URL}affiliate-user-list`, options);
      const data = await response.json();
      console.log(data.data);
      setUsers(data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getServiceType = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(`${BASE_API_URL}service-types?limit=100`, options);
    const data = response.json();

    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setServiceType(data.data.result);
    })
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    setSelectedUser(event.target.value);
    console.log(event.target)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleService = (event) => {
    console.log(event.target.value)
    setSelectedService(event.target.value);
  };

  const getAllAffiliate = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: userData.token,
        },
      };

      const response = await fetch(`${BASE_API_URL}getAffiliateLinkData?&limit=${rowsPerPage}&search=${search}&userId=${selectedUser}&serviceId=${selectedService}`, options);
      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({ token: "" });
        return;
      }
      setRows(data.data.results);
      setCount(data.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsers();
    getServiceType();
  }, []);


  useEffect(() => {
    getAllAffiliate();
  }, [search, rowsPerPage, page, selectedService, users, selectedUser]);

  //function to export data to excel
  const exportToExcel = () => {
    console.log('exportToExcel started');
    if (!rows || rows.length === 0) {
      console.error('No data to export!');
      return;
    }

    // Filter the rows to include only the required fields and add an 'ID' column
    const filteredData = rows.map((row, index) => ({
      ID: index + 1,
      Date: new Date(row.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      'User Name': row.user.name || "N/A",
      'Service Name': row.service.name || "N/A",
      'Affiliate URL': row.affiliateUrl || "N/A",
      'Affiliate URL Count': row.affiliateUrlCount || 0,
    }));


    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Affiliate Data");

    XLSX.writeFile(workbook, "affiliate_data.xlsx");
    console.log('exportToExcel ended');
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Paper style={{ margin: 10 }}>
          <EnhancedTableToolbar users={users} handleChange={handleChange} selectedUser={selectedUser} serviceType={serviceType} handleService={handleService} selectedService={selectedService} />
          <Divider />
          <Divider />
          <TableContainer>
            <Table >
              <EnhancedTableHead />
              <TableBody>
                {rows.map((row, index) => {
                  return <StyledTableRow key={index}>
                    <StyledTableCell align='left'>{page * rowsPerPage + index + 1}</StyledTableCell>
                    {/* <StyledTableCell align='left'>{row.createdAt}</StyledTableCell> */}
                    <StyledTableCell align='left'>
                      {new Date(row.createdAt).toLocaleDateString("en-GB", {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{row.user.name}</StyledTableCell>
                    <StyledTableCell align='left'>{row.service.name}</StyledTableCell>
                    <StyledTableCell align='left'>{row.affiliateUrl}</StyledTableCell>
                    <StyledTableCell align='left'>{row.affiliateUrlCount}</StyledTableCell>
                  </StyledTableRow>
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, count]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: '10px' }}
          >
            Export to Excel
          </Button>
        </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})